import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import InputField from "@/components/Common/Inputs/Input/Input";
import Button from "@/components/Common/Buttons/Button/Button";
import HeartIcon from "@/components/Common/Icons/HeartIcon/";
import LabelNew from "@/components/Common/Labels/LabelNew/LabelNew";
import Checkbox from "@/components/Common/Inputs/CheckBox/CheckBox";

export default {
    name: 'MarketplaceSortFilter',
    props: {
    },
    components: {
        Dropdown,
        InputField,
        Button,
        HeartIcon,
        LabelNew,
        Checkbox

    },
    mounted() {
    },
    data() {
        return {
            filterBoxActive: false,
            selectedOption: {
                title: 'Дата на добавяне',
                value: 'create_date',
            },
            translation: {
                sort_label: 'Подреди по',
            },
            sortingOptions: [
                {
                    title: 'Цена възходяща',
                    value: 'price_ascending',
                },
                {
                    title: 'Цена низходяща',
                    value: 'price_descending',
                },
                {
                    title: 'Дата на добавяне',
                    value: 'create_date',
                },

            ]

        }
    },
    methods: {
        toggleFilterBox() {
            this.filterBoxActive = !this.filterBoxActive
        },
        selectOption(selectedOption) {
            this.selectedOption = selectedOption;
            this.$emit('sort', selectedOption.value);
            this.filterBoxActive = false;
        },
    }
}